.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

form{
  display: flex;
  flex-direction: column;
}
input{
  margin-top: 20px;
  width: 300px;
  height: 40px;
}

button{
  margin-top: 10px;
}

.wrap_output{
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}